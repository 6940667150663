// Polyfill for inherits function
if (typeof Object.create === 'function') {
// implementation from standard node.js 'util' module
function inherits(ctor: any, superCtor: any) {
    if (superCtor) {
    ctor.super_ = superCtor;
    ctor.prototype = Object.create(superCtor.prototype, {
        constructor: {
        value: ctor,
        enumerable: false,
        writable: true,
        configurable: true
        }
    });
    }
}
window.inherits = inherits;
} else {
// old school shim for old browsers
function inherits(ctor: any, superCtor: any) {
    if (superCtor) {
    ctor.super_ = superCtor;
    const TempCtor = function () {};
    TempCtor.prototype = superCtor.prototype;
    ctor.prototype = new (TempCtor as any)();
    ctor.prototype.constructor = ctor;
    }
}
window.inherits = inherits;
}

import { createRoot } from 'react-dom/client'
import { lazy, Suspense } from 'react'
import './index.css'
import './i18n.ts'

// Lazy load App component
const App = lazy(() => import('./App.tsx'))

// Loading fallback
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen bg-background">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
  </div>
)

createRoot(document.getElementById("root")!).render(
  <Suspense fallback={<LoadingFallback />}>
    <App />
  </Suspense>
);